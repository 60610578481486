<template>
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="font-medium text-3xl text-900 mb-3">Contato</div>
    <div class="text-500 mb-5">Insira sua lista de contatos de clientes e fornecedores.</div>
    <div class="surface-card p-4 shadow-2 border-round p-fluid mb-3">

      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12">
          <label for="name" :class="{'p-error': v$.contact.name.$error}">Nome *</label>
          <div class="p-inputgroup">
            <InputText id="name" v-model.trim="contact.name"
                       @blur="v$.contact.name.$touch"
                       maxlength="50"
                       required="true" autofocus
                       :class="{'p-invalid': v$.contact.name.$error}" />
          </div>
          <small class="p-error" v-for="error of v$.contact.name.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="name">CPF</label>
          <div class="p-inputgroup">
            <InputMask mask="999.999.999-99" id="name" v-model.trim="contact.cpf"
                       maxlength="15"
                       autofocus />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <label for="name">RG</label>
          <div class="p-inputgroup">
            <InputText id="name" v-model.trim="contact.rg"
                       maxlength="25"
            />
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <label for="phone" :class="{'p-error': v$.contact.phone.$error}">Celular *</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-mobile"></i>
            </span>
            <InputMask mask="(99) 99999-9999" id="phone"
                       v-model.trim="contact.phone"
                       @blur="v$.contact.phone.$touch"
                       autofocus :class="{'p-invalid': v$.contact.phone.$error}" />
          </div>
          <small class="p-error"
                 v-for="error of v$.contact.phone.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-12 md:col-12">
          <label for="email">E-mail</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-envelope"></i>
            </span>
            <InputText id="email" v-model.trim="contact.email"
                       @blur="v$.contact.email.$touch"
                       maxlength="100"
                       autofocus :class="{'p-invalid': v$.contact.email.$error}" />
          </div>
          <small class="p-invalid" v-for="error of v$.contact.email.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="dtNascimento">Aniversário</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-calendar"></i>
            </span>
            <InputMask id="dtNascimento" mask="99/99/9999" v-model="contact.birth" required="false" :showIcon="true"/>
          </div>
        </div>
        <div class="field col-12 md:col-6">
          <label for="instagram">Instagram</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-instagram"></i>
            </span>
            <InputText id="instagram" maxlength="50" v-model.trim="contact.instagram" required="false" />
          </div>
        </div>
        <div class="field col-12 md:col-12">
          <label for="categoria" :class="{'p-error': v$.contact.categoryType.$error}">Categoria *</label>
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-list"></i>
            </span>
            <Dropdown id="categoria" v-model="contact.categoryType" :options="categorias"
                      @blur="v$.contact.categoryType.$touch"
                      option-value="code" optionLabel="name" placeholder="Selecione uma categoria"
                      autofocus :class="{'p-invalid': v$.contact.categoryType.$error}"
            />
          </div>
          <small class="p-error" v-for="error of v$.contact.categoryType.$errors"
                 :key="error.$uid">{{error.$message}}</small>
        </div>
      </div>
    </div>
    <Toolbar class="mb-2">
      <template v-slot:end>
        <div class="my-2">
          <Button label="Cancelar" icon="pi pi-times" class="p-button-outlined mr-2" @click="$router.push({name: 'contatos'})"/>
          <Button label="Salvar" icon="pi pi-check" class="p-button-success" @click="save" />
        </div>
      </template>
    </Toolbar>
  </div>

</template>

<script>
import moment from "moment";
import {useVuelidate} from "@vuelidate/core";
import {email, helpers, required} from "@vuelidate/validators";
import ContactService from "@/service/ContactService";
import { cpf } from 'cpf-cnpj-validator';

const validarCpf = (value) => !helpers.req(value) || cpf.isValid(value);

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: "contato-dialog",
  created() {
    moment.locale('pt-BR');
    this.contactService = new ContactService();
  },
  data() {
    return {
      contact: {},
      contactId: null,
      categorias: [
        {name: 'Cliente', code: 'CLIENTE'},
        {name: 'Fornecedor', code: 'FORNECEDOR'},
        {name: 'Colaborador', code: 'COLABORADOR'},
        {name: 'Loja', code: 'LOJA'}
      ],
      contactService: null,
      loader: null
    }
  },
  validations() {
    return {
      contact: {
        name: {
          required: helpers.withMessage('Campo Nome é obrigatório.', required)
        },
        phone: {
          required: helpers.withMessage("Campo Celular é obrigatório.", required)
        },
        email: {
          email: helpers.withMessage('E-mail informado é inválido.', email)
        },
        categoryType: {
          required: helpers.withMessage("Campo Categoria é obrigatório.", required)
        },
        cpf: {
          validarCpf: helpers.withMessage('CPF informado é inválido.', validarCpf)
        }
      }
    }
  },
  async mounted() {
    this.contactId = this.$route.query.id;
    if(this.contactId) {
      this.v$.$reset();
      await this.findById();
    }
  },
  methods: {
    async save() {
      const isFormValid = await this.v$.$validate();

      if(!isFormValid) {
        return;
      }
      if (this.contact.birth) {
        const dateValidate = moment(this.contact.birth, "DD/MM/YYYY", true);
        if(!dateValidate.isValid()) {
          this.$toast.add({severity:'warn', summary: 'Alerta', detail: 'Data informada não existe.', life: 3000});
          return;
        }
        this.contact.birth = moment(this.contact.birth, 'DD/MM/YYYY').format('DD/MM/YYYY');
      }
      this.loader = this.$loading.show();

      try {
        this.contact.active = true;
        const response = await this.contactService.save(this.contact);
        if(response.status === 200) {
          this.$toast.add({severity:'success', summary: 'Sucesso', detail: 'Contato cadastrado com sucesso.', life: 3000});
          this.loader.hide();
          if (!this.contactId) {
            this.contact = {};
            this.v$.$reset();
          }
        }
      } catch(e) {
        this.loader.hide();
        this.$toast.add({
          severity: 'error',
          summary: 'ERRO',
          detail: 'Ocorreu um erro interno no servidor, tente novamente mais tarde.',
          life: 3000
        });
      }
    },
    async findById() {
      this.loader = this.$loading.show();
      const response = await this.contactService.findById(this.contactId);
      if(response.status === 200) {
        this.contact = response.data;
        this.loader.hide();
      }
    }
  }
}
</script>

<style scoped>

</style>
