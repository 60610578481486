import api from '../configs/api';

export default class ContactService {

	async findAll(size, page, name) {
		if(name) {
			return api.get(`/admin/contact?size=${size}&page=${page}&name=${name}`);
		}
		return api.get(`/admin/contact?size=${size}&page=${page}`);
	}

	async findById(id) {
		return api.get(`/admin/contact/` + id);
	}

	async save(contact) {
		return api.post(`/admin/contact`, contact);
	}

	async delete(id) {
		return api.delete(`/admin/contact/` + id);
	}

	async deleteAll(ids) {
		const query = { params: { ids: ids }};
		return api().delete(`/admin/contact`, query);
	}
}
